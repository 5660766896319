/* .App {
  text-align: center;
} */

.fade-enter {
  opacity: 0;
  transform: scale(0.90);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
}
.fade-exit {
  opacity: 1;
  transform: scale(1) ;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active ,
.fade-exit-active {
  transition: opacity 500ms, transform 1000ms;
}


.slide-appear {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-appear-active {
  opacity: 1;
  transform: translateX(0);
}

.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
}